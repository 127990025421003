import styled, { createGlobalStyle, css } from 'styled-components'
import {GlobalColors, GlobalFonts} from '../../styles/GlobalStyles';
import AniLink from "gatsby-plugin-transition-link/AniLink";

export const StylesConfirmPlan = createGlobalStyle`

`
export const ContMetodosDePago = styled.div`
  margin-bottom: 50px;
`
export const TitleSectionH1 = styled.h1`
  top: ${props => props.top};
  font: 42px ${GlobalFonts.fontRegular};
  color: ${props => props.color ? props.color : GlobalColors.colorPrimary};
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
  
  &>span{
    color: ${GlobalColors.colorSecondary};
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 36px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 42px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 42px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 42px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    font-size: 42px;
  }
  @media(min-width: 1920px){
    font-size: 42px;
  }
`
export const CardTitle = css`
  font: 36px ${GlobalFonts.fontBold};
  line-height: 36px;
`;
export const TextPrimary = styled.span`
  ${CardTitle}
  color: ${GlobalColors.colorPrimary};
`;
export const TextSecondary = styled.span`
  ${CardTitle}
  color: ${GlobalColors.colorSecondary};
`;

export const Label = styled.div`
  color: #828282;
  font: 18px ${GlobalFonts.fontRegular};
  line-height: 24px;
  margin-bottom: 10px;
`;

export const LabelPlan = styled.div`
  color: ${GlobalColors.colorPrimary};
  font: 24px ${GlobalFonts.fontBold};
  line-height: 28px;
`;
export const LineSeparator = styled.div`
  width: 100%;
  border: none;
  border-top: 1px solid #BDBAE4; /* Color de la línea y grosor */
  margin: 20px 0 20px 0;
`;

export const ContPlanData = styled.div`
  align-items: center;
  vertical-align: middle;
  display: flex;
  justify-content: space-between;
`;

export const ContServicesDetailItem = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
`
export const ContServicesDetailItemIco = styled.div`
  width: 33px;
  height: 33px;
  margin-right: 15px;
  cursor: pointer;
`
export const ContServicesDetailItemInfo = styled.div`
  width: 84%;
  height: auto;
  font: 18px ${GlobalFonts.fontRegular}; 
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: ${GlobalColors.colorText};
`;
export const ContProduct = styled.div`
  width: 100%;
  margin: 15px 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`
export const ContProductItemLeft = styled.div`
  width: 100%;
`
export const ContProductItemRight = styled.div`
  width: 20%;
  display: flex;
  justify-content: flex-end;
`
export const ContField = styled.div`
  position: relative;
  width: 100%;
  height: 66px;
  margin-bottom: 0px;
`
export const LabelCode = styled.div`
  color: #333333;
  font: 18px ${GlobalFonts.fontBold};
  font-style: normal;
  font-weight: 800;
  line-height: 28px;
  margin-bottom: 10px;
`;

export const InputPromo = styled.input`
  width: 100%;
  height: 60px;
  padding: 10px;
  text-align: center;
  border-radius: 8px;
  border: 1px solid #BDBAE4;
`;
export const AmountToPay = styled.div`
  color: #4F4F4F;
  font: 28px ${GlobalFonts.fontBold};
  font-style: normal;
  font-weight: 800;
  line-height: 36px;
  margin: 12px 0;
`;

export const AmountToPayDescr = styled.div`
  color: #333333;
  font: 16px ${GlobalFonts.fontBold};
  font-style: normal;
  font-weight: 800;
  line-height: 22px;
  margin: 12px 0;
`;
export const ContContactInfouser = styled.div`
  display: block;
`;
export const TextPayment = styled.div`
  color: white;
  font: 17px ${GlobalFonts.fontExtraBold};
  font-style: bold;
  font-weight: 700;
  line-height: 23px;
`;
export const LabelContact = styled.div`
  color: #5B52BC;
  font: 20px ${GlobalFonts.fontExtraBold};
  font-style: bold;
  font-weight: 800;
  line-height: 28px;
`;
export const ContCardDescSeg = styled.div`
  color: #333333;
  font: 15px ${GlobalFonts.fontPrimary};
  font-style: bold;
  font-weight: 600;
  line-height: 23px;
  margin-bottom: 15px;
`;
export const ContTextMinus = styled.div`
  color: #828282;
  font: 13px ${GlobalFonts.fontPrimary};
  font-style: bold;
  font-weight: 600;
  line-height: 16px;
  margin-bottom: 15px;
  text-align: justify;
`;

export const ContConektaImg = styled.div`
  text-align: center;
  align-items: center;
  padding-top: 20px;
`
export const ContTotalItem = styled.div`
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
`

export const ContTotalNumber = styled.div`
  width: auto;
  height: 36px;
  margin-right: 15px;
  font: 28px ${GlobalFonts.fontBold};
  font-style: normal;
  font-weight: 800;
  line-height: 36px;
  color: ${GlobalColors.colorPrimary};
  
  &>span{
    font: 15px ${GlobalFonts.fontRegular};
    font-style: normal;
    font-weight: 600;
    line-height: 23px;
    color: ${GlobalColors.colorPrimary};
  }
`
export const ContTotalTextIVA = styled.div`
  position: relative;
  height: 36px;
  bottom: -10px;
  font: 15px ${GlobalFonts.fontRegular};
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  color: ${GlobalColors.colorTextDiscreet};
`

export const ContExitPromo = styled.div`
  text-align: center;
  margin-bottom: 5px;
  font: 15px ${GlobalFonts.fontRegular};
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  color: ${GlobalColors.colorPrimary};
`;

export const ContMessage = styled.div`
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ContMessageDetail = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
  @media(min-width: 300px) and (max-width: 1023px){
    width: 100%;
    padding: 0 15px;
  }
  @media(min-width: 1024px){
  }
`;

export const ContMessageDetailInfo = styled.div`
  font: 36px ${GlobalFonts.fontBold};
  font-style: normal;
  font-weight: 800;
  line-height: 36px;
  text-align: center;
  color: ${GlobalColors.colorPrimary};
`
export const ContMessageDetailInfoMinor = styled.div`
  font: 18px ${GlobalFonts.fontPrimary};
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: ${GlobalColors.colorPrimary};

  @media(min-width: 300px) and (max-width: 1023px){
    width: 100%;
  }
  @media(min-width: 1024px){
    width: 415px;
  }
`