import React, {useEffect, useState} from 'react';
//import {TransitionState} from 'gatsby-plugin-transition-link';
//import AniLink from 'gatsby-plugin-transition-link/AniLink';
import {
  Column, Columns, Container,
  //Modal,
  //ModalBackground,
  //ModalContent,
  //ModalClose,
} from 'bloomer';
import {
  ContMetodosDePago, TitleSectionH1, TextPrimary, TextSecondary, StylesConfirmPlan, ContTotalItem, ContTotalNumber, ContTotalTextIVA,
  Label, LabelPlan, LineSeparator, ContPlanData, ContServicesDetailItem, ContServicesDetailItemIco, ContExitPromo,
  ContServicesDetailItemInfo, ContProduct, ContProductItemLeft, ContProductItemRight, LabelCode, ContField, InputPromo,
  AmountToPay, AmountToPayDescr, ContContactInfouser, LabelContact, ContCardDescSeg, ContTextMinus, TextPayment, ContConektaImg,
  ContMessage, ContMessageDetail, ContMessageDetailInfo, ContMessageDetailInfoMinor,
} from './styles';
import {
  //ContColumnInner,
  //ContColumnsInLine,
  //ContImportantElement,
  Linea,
  //PieTitleSectionMini,
  //TitlePlanSummary,
  //Separador,
  //TitleSectionMini,
  //TitleSectionSecondary,
  //NamePlanSummary,
  //GlobalColors,
  BtnPrimary,
  //TitleSectionMiniMobile,
  //PieTitleSectionMiniMobile,
  //TitleSection,
  PieTitleSection,
} from '../../styles/GlobalStyles';
import { InputTextMyS } from '../ui/inputText/InputText';
//import useWindowDimensions from '../../helpers/useWindowDimensions';
import IconEdit from '../../images/icon-edit.svg';
import IconPhone from '../../images/ico-phone.svg';
import IconBell from '../../images/ico-bell.svg';
import IconPlus from '../../images/ico-plus.svg';
//import ImgLoader from '../../images/loader.gif';
//import IconDown from '../../images/icon-down.svg';
//import IconUp from '../../images/icon-up.svg';
//import IconConekta from '../../images/conektapower.png';
import { navigate } from 'gatsby';
import axios from 'axios';
//import {Formik, Form} from 'formik';
//import * as Yup from 'yup';
////import InputTextMySMaterial from '../ui/InputTextMySMaterial';
//import SelectMySMaterial from '../ui/SelectMySMaterial';
//import CheckboxMySMaterial from '../ui/CheckboxMySMaterial';
//import {CheckboxMyS} from '../ui/Checkbox';

export const ConfirmPlan = () => {
  const API_KEY = `${process.env.API_KEY}`;
  const CONEKTA_PUBLIC_KEY = `${process.env.CONEKTA_PUBLIC_KEY}`;
  let URL_TOKEN = `${process.env.GATSBY_API_URL}:${process.env.PORT}/token`;
  let URL_CLIENT = `${process.env.GATSBY_API_URL}:${process.env.PORT}/clients`;
  let URL_ZIPCODE = `${process.env.GATSBY_API_URL}:${process.env.PORT}/zipcodes`;
  let URL_PROMO = `${process.env.GATSBY_API_URL}:${process.env.PORT}/validate`;
  //let dataResponse;
  //let dataPersonal;
  //let dataInvoice;
  //let dataPlan;
  if ( process.env.NODE_ENV === 'production' ) {
    URL_CLIENT = `${process.env.GATSBY_API_URL}/clients`;
    URL_TOKEN = `${process.env.GATSBY_API_URL}/token`;
    URL_ZIPCODE = `${process.env.GATSBY_API_URL}/zipcodes`;
    URL_PROMO = `${process.env.GATSBY_API_URL}/validate`;
  }
  const [services, setServices] = useState([
    {
      label: '100 llamadas entrantes'
    },
    {
      label: '100 notificaciones'
    },
    {
      label: '10 agendas'
    },
    {
      label: 'Llamada adicional: $16.00 MXN'
    },
    {
      label: 'Agenda adicional: $19.00 MXN'
    },
  ]);
  const [promoCode, setPromoCode] = useState('');
  const [registerData, setRegisterData] = useState({
    name: '',
    email: '',
    phone: '',
    nameView: '',
    //nameCardname: '',
    //cardnumber: '',
    //carddate: '',
    //cardcvv: '',
    //accept: false,
  });
  const [errors, setErrors] = useState({});
  const [showMessageError, setShowMessageError] = useState(false);
  const [messageError, setMessageError] = useState('');
  const [price, setPrice] = useState();
  const [namePlan, setNamePlan] = useState();
  const [tokenConekta, setTokenConekta] = useState({});
  const [discountPromo, setDiscountPromo] = useState({
    discountTotal: 0,
    discountPorcentaje: 0,
    discountQty: 0,
    showDisccount: false,
    promocod_colaborador:'',
    promocod_customer:'',
  });
  const [msgPromoSuccess, setMsgPromoSuccess] = useState(false);
  const [promoApplied, setPromoApplied] = useState(false);

  const handleChangePromo = (event) => {
    setPromoCode(event.target.value);
  };
  const handleChangeInput = (field, value) => {
    setRegisterData({ ...registerData, [field]: value });
    setErrors((prevErrors) => ({ ...prevErrors, [field]: '' }));
    //console.log('value en handleChangeInput', value);
    //console.log('registerData=>', registerData);
  };
  //const onAccept = () => {
  //  const ACCEPT = !registerData.accept;
  //  setRegisterData({
  //    ...registerData,
  //    accept: ACCEPT,
  //  });
  //};
  const sendInfo = (values) => {
    let newErrors = {}; // Nuevo objeto de errores

    // Validar los campos requeridos del estado
    //if (registerData.name.trim() === '') {
    //  newErrors = { ...newErrors, name: 'Este campo es requerido' };
    //}
    //if (registerData.phone.trim() === '') {
    //  newErrors = { ...newErrors, phone: 'Por favor, ingrese su número de teléfono.' };
    //} else if (!/^\d{10}$/.test(registerData.phone)) {
    //  newErrors = { ...newErrors, phone: 'El número de teléfono debe tener exactamente 10 dígitos.' };
    //}
    //if (!registerData.email.trim()) {
    //  newErrors = { ...newErrors, email: 'Este campo es requerido' };
    //} else if (!/^\S+@\S+\.\S+$/.test(registerData.email)) {
    //  newErrors = { ...newErrors, email: 'Por favor, ingrese un correo electrónico válido.' };
    //}

    // Validar el campo del nombre de la tarjeta
    //if (registerData.nameCardname.trim() === '') {
    //  newErrors = { ...newErrors, nameCardname: 'Por favor, ingrese el nombre del titular de la tarjeta.' };
    //}

    // Validar el campo del número de la tarjeta
    //if (registerData.cardnumber.trim() === '') {
    //  newErrors = { ...newErrors, cardnumber: 'Por favor, ingrese el número de la tarjeta.' };
    //} else if (!/^\d{16}$/.test(registerData.cardnumber)) {
    //  newErrors = { ...newErrors, cardnumber: 'El número de la tarjeta debe tener 16 dígitos.' };
    //}

    //if (registerData.carddate.trim() === '') {
    //  newErrors = { ...newErrors, carddate: 'Por favor, ingrese la fecha de expiración de la tarjeta.' };
    //} else if (!/^(0[1-9]|1[0-2])\/\d{2}$/.test(registerData.carddate)) {
    //  newErrors = { ...newErrors, carddate: 'Ingrese una fecha de expiración válida en el formato MM/YY.' };
    //}

    //if (registerData.cardcvv.trim() === '') {
    //  newErrors = { ...newErrors, cardcvv: 'Por favor, ingrese el código CVV de la tarjeta.' };
    //} else if (!/^\d{3,4}$/.test(registerData.cardcvv)) {
    //  newErrors = { ...newErrors, cardcvv: 'El código CVV debe tener 3 o 4 dígitos.' };
    //}

    //if (!registerData.accept) {
    //  newErrors = { ...newErrors, accept: 'Debe aceptar el acuerdo' };
    //}

    // Validar los campos requeridos desde values y el formato correcto
    if (!values.name.trim()) {
      newErrors.name = 'El nombre es requerido';
    }
    if (!values.phone.trim()) {
        newErrors.phone = 'El teléfono es requerido';
    } else if (!/^(\+52)?\d{10}$/.test(values.phone.trim())) {
        newErrors.phone = 'El teléfono debe tener 10 dígitos o incluir el código de país +52';
    }
    if (!values.email.trim()) {
        newErrors.email = 'El correo electrónico es requerido';
    } else if (!/\S+@\S+\.\S+/.test(values.email.trim())) {
        newErrors.email = 'El correo electrónico tiene un formato inválido';
    }

    // Actualizar el estado de errores
    setErrors(newErrors);

    // Si hay algún error, no envíes la información y limpia el iframe
    if (Object.keys(newErrors).length > 0) {
      console.log('Por favor, corrija los errores antes de enviar la información.');
      getToken();
      let divContent = document.getElementById('conektaIframeContainer')
      divContent.style.background = "#FFF!important";
      console.log('divContent=>', divContent);
      while (divContent.hasChildNodes()) {
        divContent.removeChild(divContent.firstChild);
      }
      return;
    }

    

    //console.log('valores de form=>', registerData);
    //console.log('plan data=<', services);
    onSendPay(values);
  };

  const onSendPay = (data) => {
    //console.log('data de onSendPay=>', data);
    axios({
      method: 'POST',
      url: URL_CLIENT,
      //url: 'https://webhook.site/1a1172f4-bc67-4363-a74d-e4bf3ee14492',
      data: data,
      headers: {
        'x-api-key': API_KEY,
        'Content-Type': 'application/json',
      },
    }).then(function (response) {
      //console.log('axios => response =>', response);

      let status = response.data.data.success;
      //console.log('axios => response => status =>', status);
      if ( status === true ) {
        navigate('/welcome', {state: response.data.data});
        //setRedirect(response.data.data.redirect);
        //setTimeout(function () {
        //  document.getElementById('goto-welcome').click(); // ir a /welcome
        //}, 3000);
      } else {
        // cambiar el mensaje
        //console.log('response.data.data.error=>', response.data.data.error);
        setShowMessageError(true);
        //console.log('error=><<<<', response.data.data.error);
        setMessageError(response?.data?.data?.error);
        //setMessage(response.data.data.error);
        //setIsOpen(false);
        //setiframeLoadLoad(true);
        //let divContent = document.getElementById('conektaIframeContainer')
        //divContent.style.background = "#FFF!important";
        //while (divContent.hasChildNodes()) {
        //  divContent.removeChild(divContent.firstChild);
        //}
      }
    })
    .catch(function (error) {
      console.log('error en ctach=<', error);
      setMessageError(error);
      //setIsOpen(false);
      //setiframeLoadLoad(true);
      //let divContent = document.getElementById('conektaIframeContainer')
      //divContent.style.background = "#FFF!important";
      //// document.getElementsByClassName('IframeTokenizer').style.padding = "10px";
      //while (divContent.hasChildNodes()) {
      //  divContent.removeChild(divContent.firstChild);
      //}
      let message = 'Ocurrió un error';
      if ( error.hasOwnProperty('message') ) {
        message = error.message.toString();
        setMessageError(error.message.toString());
      }

      setMessageError(message);
      setShowMessageError(true);

      //console.log('message =>', message);
    });
  }

  const getToken = () => {
    axios({
        method: 'post',
        url: URL_TOKEN,
        data: '{}',
        headers: {
          'x-api-key': API_KEY,
          'Content-Type': 'application/json',
        },
      }).then(function (response) {
        setTokenConekta(response.data.data);
        //iframeConekta(tokenConekta);
        iframeConekta(response.data.data);
      })
      .catch(function (error) {
  
        let message = 'Ocurrió un error, intente más tarde';
        if ( error.hasOwnProperty('message') ) {
          message = error.message.toString();
          console.log(error.message.toString());
        }
  
        //setShowMessageError(true);
  
        //console.log('message =>', message);
        //console.log(error);
        //console.log(JSON.stringify(error));
      });
  };

  const iframeConekta = (conekta_token) =>{
    //console.log('conekta_token en iframeConekta=>', conekta_token);
     window.ConektaCheckoutComponents.Card({
      targetIFrame: "#conektaIframeContainer",
      //Este componente "allowTokenization" permite personalizar el tokenizador, por lo que su valor siempre será TRUE
      allowTokenization: true,
      checkoutRequestId: conekta_token.checkout.id, // // Checkout request ID, es el mismo ID generado en el paso 1
      publicKey: CONEKTA_PUBLIC_KEY, // Llaves: https://developers.conekta.com/docs/como-obtener-tus-api-keys
      options: {
        styles: {
          // inputType modifica el diseño del Web Checkout Tokenizer
          //        inputType: 'basic' // 'basic' | 'rounded' | 'line'
          inputType: 'rounded',
          // buttonType modifica el diseño de los campos de llenado de información del  Web Checkout Tokenizer
          //        buttonType: 'basic' // 'basic' | 'rounded' | 'sharp'
          buttonType: 'rounded',
          //Elemento que personaliza el borde de color de los elementos
          states: {
            empty: {
              borderColor: '#F44336' // Código de color hexadecimal para campos vacíos
            },
            invalid: {
              borderColor: '#F44336' // Código de color hexadecimal para campos inválidos
            },
            valid: {
              borderColor: '#0079c1' // Código de color hexadecimal para campos llenos y válidos
            }
          }
        },
        languaje: 'es', // 'es' Español | 'en' Ingles
        //Elemento que personaliza el botón que finzaliza el guardado y tokenización de la tarjeta
        button: {
          colorText: '#ffffff', // Código de color hexadecimal para el color de las palabrás en el botón de: Alta de Tarjeta | Add Card
          text: 'Iniciar prueba', //Nombre de la acción en el botón ***Se puede personalizar
          backgroundColor: '#ff8675!important' // Código de color hexadecimal para el color del botón de: Alta de Tarjeta | Add Card
        },
        //Elemento que personaliza el diseño del iframe
        iframe: {
          colorText: '#5B52BC',  // Código de color hexadecimal para el color de la letra de todos los campos a llenar
          backgroundColor: '#FFFFFF' // Código de color hexadecimal para el fondo del iframe, generalmente es blanco.
        }
      },
      onCreateTokenSucceeded: function(token) {
        
        let name = document.getElementById('txtName').value;
        let phone = document.getElementById('txtPhone').value;
        let email = document.getElementById('txtEmail').value;
        let priceInput = document.getElementById('txtPrice').value;
        let planNameInput = document.getElementById('txtPlanName').value;
      
        //if(requireinvoiceInput === 'false'){
        //  municipioInput = document.getElementsByName('municipality')[0].value;
        //  cpInput = document.getElementsByName('cp')[0].value;
        //  suburbInput = document.getElementsByName('suburb')[0].value;
        //  estadoInput = document.getElementsByName('state')[0].value;
        //}else{
        //  municipioInput = document.getElementsByName('municipalityinvoice')[0].value;
        //  suburbInput = document.getElementsByName('suburbinvoice')[0].value;
        //  cpInput = document.getElementsByName('cpinvoice')[0].value;
        //  estadoInput = document.getElementsByName('stateinvoice')[0].value;
        //}
        const priceInputInt = parseInt(priceInput, 10);
        const priceString = window?.history?.state?.price;
        const priceInteger = parseInt(priceString, 10);
        let finalPrice = 0;
        let finalPlanName = '';
        if (priceInteger === priceInputInt) { //Si no se aplica code promo
          finalPrice = priceInputInt;
          finalPlanName = planNameInput || window?.history?.state?.name
        } else {
          finalPrice = priceInputInt //Se pasa price por que tiene code promo
          finalPlanName = planNameInput || window?.history?.state?.name;
        }
        
        const data = {
          name:                 name,
          last_name:            '',
          second_lastname:      '',
          email:                email,
          phone:                phone,
          methodPay:            'TARJETA',
          plan:                 finalPlanName,
          price:                finalPrice,
          diary:                'none',
          additional_service_1: '0',
          additional_service_2: '0',
          discount:             promoCode || '',
          discountColaborador:  '',
          card_token:           '',
          has_invoice:          '', //val.requireinvoice ? '1' : '0',
          type_of_person:       '', //val.typeperson,
          rfc:                  '', //val.rfc,
          company_name:         '', //val.businessname,
          zip_code:             '', //val.cp,
          street:               '', //val.street,
          outdoor_number:       '', //val.externalnumber,
          interior_number:      '', //val.internalnumber,
          suburb:               '', //val.municipality,
          municipality:         '', //val.municipality,
          state:                '', //val.state,
          token:                token
        };
        //console.log('data armada antes de sendInfo=>', data);
        sendInfo(data); // funcion para validar data fuera de la tarjeta.         
      },
      onCreateTokenError: function(error) {
        getToken()
        console.log('error=>', error);
        let divContent = document.getElementById('conektaIframeContainer')
        divContent.style.background = "#FFF!important";
        // document.getElementsByClassName('IframeTokenizer').style.padding = "10px";
        while (divContent.hasChildNodes()) {
          divContent.removeChild(divContent.firstChild);
        }
        let message = 'Ocurrió un error, intente más tarde';
        if ( error.hasOwnProperty('message') ) {
          message = error.message.toString();
          //setMessageError(error.message.toString());
        }
        //setShowMessageError(true);
      }
    })
   };

  function aplicarPromo(){
    let data = {
      discount: promoCode,
      discountColaborador: promoCode,
    }
    if (promoCode !== '') {
      axios({
        method: 'post',
        url: URL_PROMO,
        data: data,
        headers: {
          'x-api-key': API_KEY,
          'Content-Type': 'application/json',
        },
      }).then(function (response) {
        //console.log(response, 'response de descuento');
        //console.log(response.data.data.code, 'responsedata.data.code');
        if(response.data.data.code !== 200){
          setDiscountPromo({
            ...discountPromo,
            discountTotal: 0,
            showDisccount: false,
            discountPorcentaje: 0,
            discountQty: 0,
            promocod_colaborador:'',
            promocod_customer:'',
          })
          setPromoApplied(false);
        }else{
          //console.log('precio en promo=>', price);
          //console.log('typeof precio en promo=>', typeof price);
          //console.log('descuento de resonsoe=>', response.data.data.result.totalDiscount);
          let priceBody = (typeof price == 'number')? price : price.replace(',','');
          const unit_price = (typeof priceBody == 'string')?Number(priceBody) : price;

          let priceDis = ((unit_price *  response.data.data.result.totalDiscount)/100);
          let tot = Math.round(unit_price - priceDis)
          setDiscountPromo({
            ...discountPromo,
            discountTotal: tot,
            discountQty: Math.round(priceDis),
            showDisccount: true,
            discountPorcentaje: response.data.data.result.totalDiscount,
            promocod_colaborador: response.data.data.result.promocod_colaborador,
            promocod_customer: response.data.data.result.promocod_customer,
          })
          setMsgPromoSuccess(true);
          //console.log('tot=>', tot);
          setPrice(tot);
          setPromoApplied(true);
        }
      })
      .catch(function (error) {
        console.log(error, 'error');
      });
    }
  }

  const MessageCompError = () => {
    //console.log('messageError en component=<', messageError);
    return (
      <ContMessageDetail>
        <ContMessageDetailInfo>Hubo un error</ContMessageDetailInfo>
        <ContMessageDetailInfoMinor>{messageError}</ContMessageDetailInfoMinor>
      </ContMessageDetail>
    );
  };

  useEffect(() => {
    // Check if window is defined (so if in the browser or in node.js).
    const isBrowser = typeof window && localStorage !== "undefined";
    if (isBrowser) {
    const priceString = window?.history?.state?.price;
      if(priceString === undefined) {
        navigate('/registrate');
      } else {
        const priceWithoutCommas = priceString.replace(/,/g, '');
        const priceInteger = parseInt(priceWithoutCommas, 10);
        setPrice(priceInteger);
        setNamePlan(window?.history?.state?.name);
        setRegisterData({
          name: window?.history?.state?.personalData?.name || '',
          email: window?.history?.state?.personalData?.email || '',
          phone: window?.history?.state?.personalData?.phone || '',
          nameView: window?.history?.state?.personalData?.name || '',
        })
        getToken();
        const fetchServices = () => {
          const services2 = window?.history?.state?.stService;
          if (services2 != null) {
            const services2Array = Object.values(services2)
              .filter(item => typeof item === 'object' && item.hasOwnProperty('label'));
            if (services2Array.length > 0) {
              setServices(services2Array);
              return;
            }
          } 
        
          // Si no se encontraron servicios en services2, intentar obtenerlos de localStorage
          const services3 = localStorage.getItem('myServices');
          if (services3) {
            const parsedServices3 = JSON.parse(services3);
            if (parsedServices3 && parsedServices3.length > 0) {
              setServices(parsedServices3);
              return;
            }
          }

          navigate('/registrate');
        };

        fetchServices();
      }
    }
  }, []);

  if (price === undefined) {
    return null;
  }

  return (
    <ContMetodosDePago id={'PrincipalConfirmPlan'}>
      <StylesConfirmPlan />
      <Container isFluid={true}>
        <Columns id={'containertitle'} className={"full-height"} isMultiline={true}>
          <Column
              className={"no-display-mobile"}
              isSize={{ mobile: 0, tablet: 0, desktop: 2, widescreen: 2 }}
          >
            &nbsp;
          </Column>
          <Column className={"center-column"} isSize={{ mobile: 12, tablet: 12, desktop: 8, widescreen: 8 }}>
            <Columns className={"full-height"} isMultiline={true}>
              <Column
                isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}
              >
                <TitleSectionH1 className={"animate__animated animate__fadeInUp"}>
                  ¡ {registerData?.nameView} Comienza a disfrutar hoy de tu secretaria CallMatik!
                </TitleSectionH1>
                <Column
                  isSize={{
                    mobile: 12,
                    tablet: 12,
                    desktop: 12,
                    widescreen: 12,
                  }}
                >
                  <PieTitleSection
                    className={"animate__animated animate__fadeInDown"}
                  >
                    <div>
                      Puedes cancelar cuando quieras
                    </div>
                  </PieTitleSection>
                </Column>
              </Column>
            </Columns>
          </Column>
          <Column
              className={"no-display-mobile"}
              isSize={{ mobile: 0, tablet: 0, desktop: 2, widescreen: 2 }}
          >
            &nbsp;
          </Column>
        </Columns>
        <Columns id={'containerCard'} className={"full-height"} isMultiline={true}>
          <Column
              className={"no-display-mobile"}
              isSize={{ mobile: 0, tablet: 0, desktop: 2, widescreen: 2 }}
          >
            &nbsp;
          </Column>
          {/** Columna central de cards */}
          <Column className={"center-column"} isSize={{ mobile: 12, tablet: 12, desktop: 8, widescreen: 8 }}>
            <Columns className={"full-height"} isMultiline={true}>
              <Column className={"center-column"} isSize={{ mobile: 12, tablet: 12, desktop: 6, widescreen: 6 }}>
                <div style={{backgroundColor: '#F4F3FF', padding: '40px 30px'}}>
                  <div style={{marginBottom: '10px'}}>
                    <TextPrimary>Resumen</TextPrimary> <TextSecondary>del plan</TextSecondary>
                  </div>
                  <div>
                    <Label>
                      Revisa el detalle de tu plan.
                    </Label>
                    <LineSeparator />
                  </div>
                  <div>
                    <ContPlanData>
                      <div>
                        <Label>
                          Producto
                        </Label>
                        <LabelPlan>
                          {namePlan.charAt(0).toUpperCase() + namePlan.slice(1)} $ {price} MXN/Mes (IVA Incluido)
                        </LabelPlan>
                      </div>
                      <div onClick={() => {
                        navigate('/plan');
                      }}>
                        <ContServicesDetailItemIco>
                          <img src={IconEdit} alt={''}/>
                        </ContServicesDetailItemIco>
                      </div>
                      
                    </ContPlanData>
                    <LineSeparator />
                  </div>
                  <div>
                    <Label>
                      Servicios
                    </Label>
                    <div style={{paddingTop: '10px'}}>
                      {services.map((item, index) => {
                        const icon = index === 0 ? IconPhone : index === 1 ? IconBell : IconPlus;
                        return (
                          <ContServicesDetailItem key={index}>
                            <ContServicesDetailItemIco>
                              <img src={icon} alt={''}/>
                            </ContServicesDetailItemIco>
                            <ContServicesDetailItemInfo>{item.label}</ContServicesDetailItemInfo>
                          </ContServicesDetailItem>
                        );
                      })}
                    </div>
                    <LineSeparator />
                  </div>
                  <div>
                    <ContProduct>
                      <ContProductItemLeft>
                          <LabelCode>
                            ¿Tienes un código promocional?
                          </LabelCode>
                        <ContField>
                          <InputPromo
                            type="text"
                            placeholder="Introduce tu código promocional"
                            value={promoCode}
                            onChange={handleChangePromo}
                          />
                        </ContField>
                        <div>
                          {msgPromoSuccess && discountPromo?.discountPorcentaje !== 0  ? 
                          (
                            <ContExitPromo>
                              Código aplicado exitosamente
                            </ContExitPromo>
                          ) : (null)
                          }
                        </div>
                        <div className={'full-width'}>
                          <BtnPrimary onClick={() => aplicarPromo()} disabled={promoApplied}>
                            Aplicar
                          </BtnPrimary>
                        </div>
                      </ContProductItemLeft>
                      
                    </ContProduct>
                    <LineSeparator />
                  </div>
                  <div>
                    <div>
                      {discountPromo.showDisccount && discountPromo?.discountPorcentaje !== 0 ?
                        <>
                          <ContTotalItem>
                            <ContTotalNumber>{discountPromo.discountPorcentaje}<span>%</span></ContTotalNumber>
                            <ContTotalTextIVA>(Descuento)</ContTotalTextIVA>
                          </ContTotalItem>
                          <Linea/>
                          {/*
                          <ContTotalItem>
                            <ContTotalNumber>${discountPromo.discountTotal}<span></span></ContTotalNumber>
                          </ContTotalItem>
                          */}
                          </>
                          :
                          <>
                          </>
                        }
                    </div>
                    <div>
                      {/*
                      <AmountToPay>
                        $0.00 en tu primer mes
                      </AmountToPay>
                      */}
                      <AmountToPayDescr>
                        {/*
                        Después de los primeros 30 días pagarás 
                        <br/>
                        
                        <br/> 
                        Puedes darte de baja cuando quieras
                      */}
                      </AmountToPayDescr>
                      <LabelPlan>
                          Tu Plan ${price} MXN/mes
                      </LabelPlan>
                    </div>
                    <div>
                      <AmountToPayDescr>
                        Inicialmente en la contratación solo se realiza un cargo de $5.00 MXN para verificar el pago.
                        <br />
                        Sobre la tarjeta registrada posteriormente recibirá los cargos mensuales de acuerdo a su plan de contratación.
                      </AmountToPayDescr>
                    </div>
                  </div>
                </div>
              </Column>
              <Column className={"center-column"} isSize={{ mobile: 12, tablet: 12, desktop: 6, widescreen: 6 }}>
                <div style={{textAlign: 'center', backgroundColor: '#5B52BC', padding: '10px 10px', marginBottom: '20px'}}>
                  <TextPayment>
                    Sin permanencia. Podrás dar de baja cuando quieras
                  </TextPayment>
                </div>
                <ContContactInfouser>
                  <div>
                    <LabelContact>
                      Información del contacto
                    </LabelContact>
                    <LineSeparator />
                  </div>
                  <div>
                    <InputTextMyS
                      name={'txtName'}
                      label={'Nombre'}
                      type={'text'}
                      value={registerData.name}
                      onChange={(e) => handleChangeInput('name', e)}
                      requiredField={true}
                      parentValidate={false}
                      autoFocus={false}
                      readOnly={false}
                      showError={!!errors.name}
                      error={errors.name}
                    />
                    <InputTextMyS
                      name={'txtPhone'}
                      label={'Número telefónico'}
                      type={'tel'}
                      value={registerData.phone}
                      onChange={(e) => handleChangeInput('phone', e)}
                      requiredField={true}
                      parentValidate={false}
                      autoFocus={false}
                      readOnly={false}
                      showError={!!errors.phone}
                      error={errors.phone}
                    />
                    <InputTextMyS
                      name={'txtEmail'}
                      label={'Correo electrónico'}
                      type={'email'}
                      value={registerData.email}
                      onChange={(e) => handleChangeInput('email', e)}
                      requiredField={true}
                      parentValidate={false}
                      autoFocus={false}
                      readOnly={false}
                      showError={!!errors.email}
                      error={errors.email}
                    />
                    <input
                      id="txtPrice"
                      type="text"
                      name="txtPrice"
                      value={price}
                      style={{display: 'none'}}
                    />
                    <input
                      id="txtPlanName"
                      type="text"
                      name="txtPlanName"
                      value={namePlan}
                      style={{display: 'none'}}
                    />
                    <LineSeparator />
                  </div>
                  <ContMessage id="msg-content">
                    {showMessageError
                      ? <MessageCompError/>
                      : null
                    }
                  </ContMessage>
                  {/*
                  <div>
                    <ContCardDescSeg>
                      Por tu seguridad y la de nosotros, debemos confirmar que la tarjeta de crédito sea válida.
                    </ContCardDescSeg>
                    <InputTextMyS
                      name={'txtCardName'}
                      label={'Nombre del tutor'}
                      type={'text'}
                      value={registerData.nameCardname}
                      onChange={(e) => handleChangeInput('nameCardname', e)}
                      requiredField={true}
                      parentValidate={false}
                      autoFocus={false}
                      readOnly={false}
                      showError={!!errors.nameCardname}
                      error={errors.nameCardname}
                    />
                    <InputTextMyS
                      name={'txtCardNumber'}
                      label={'Número de la tarjeta'}
                      type={'creditcard'}
                      value={registerData.cardnumber}
                      onChange={(e) => handleChangeInput('cardnumber', e)}
                      requiredField={true}
                      parentValidate={false}
                      autoFocus={false}
                      readOnly={false}
                      showError={!!errors.cardnumber}
                      error={errors.cardnumber}
                    />
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      <div>
                        <InputTextMyS
                          name={'txtCardDate'}
                          label={'Mes / Año'}
                          type={'expiration'}
                          value={registerData.carddate}
                          onChange={(e) => handleChangeInput('carddate', e)}
                          requiredField={true}
                          parentValidate={false}
                          autoFocus={false}
                          readOnly={false}
                          showError={!!errors.carddate}
                          error={errors.carddate}
                        />
                      </div>
                      <div>
                        <InputTextMyS
                          name={'txtCardCvv'}
                          label={'CVV'}
                          type={'cvv'}
                          value={registerData.cardcvv}
                          onChange={(e) => handleChangeInput('cardcvv', e)}
                          requiredField={true}
                          parentValidate={false}
                          autoFocus={false}
                          readOnly={false}
                          showError={!!errors.cardcvv}
                          error={errors.cardcvv}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <ContTextMinus>
                      Al hacer clic en el botón Iniciar membresía, aceptas nuestros Términos de uso y nuestra 
                      Declaración de privacidad, aceptas que tu membresía siga activa automáticamente hasta que la 
                      canceles. Puedes cancelar en cualquier momento para evitar cargos futuros haciendo clic 
                      en “Cancelar mi suscripción” desde “Mi cuenta”. Una vez que finalice el periodo de prueba gratis, 
                      tan solo pagas la cantidad del plan que seleccionaste. Durante el periodo de prueba, 
                      solo se cobrará $3.00MXN; puedes cancelar en cualquier momento.
                    </ContTextMinus>
                    <CheckboxMyS
                      label={'Acepto términos y tondiciones'}
                      checked={registerData.accept}
                      onChange={() => onAccept()}
                    />
                  </div>
                  <div className={'full-width'}>
                    <BtnPrimary onClick={() => sendInfo()}>
                      Inicia prueba por $0.00
                    </BtnPrimary>
                  </div>
                  */}
                <div id="conektaIframeContainer" style={{height: '550px', marginBottom:0}}></div>
                </ContContactInfouser>
                {/*
                <ContConektaImg>
                  <img src={IconConekta} alt={'conektaimg' } />
                </ContConektaImg>
                 */}
              
              </Column>
            </Columns>
          </Column>
          {/** Fin Columna central de cards */}
          <Column
              className={"no-display-mobile"}
              isSize={{ mobile: 0, tablet: 0, desktop: 2, widescreen: 2 }}
          >
            &nbsp;
          </Column>
        </Columns>
      </Container>
    </ContMetodosDePago>
  );
};